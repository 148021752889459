import React from 'react';
import { styled, withStyle } from 'styletron-react';
import { Link } from 'react-router-dom';
import style from '../../../style';
import ShipmentList from './ShipmentList';
import Form from './ShipmentForm';
// import { TextAreaField as TextArea } from '../../../components/Field';
import LocationSelector from './LocationSelector';
import { PageContent, PageHeading, PageHeader } from '../../../components/Page';
import { Col, Row, RightAlign } from '../../../components/Layout';
import { makeButtonStyle } from '../../../components/Button';
import { LOCATIONS_QUERY } from '../../locations/graph';
import { TABS_COUNT_QUERY } from '../index';
import { Query, withApollo } from 'react-apollo';

const RegisterView = ({ contract, batch = {}, onAdd, remove, match, selectLocation, client }) => (
  <div>
    <PageHeader>
      <PageHeading>Register Shipment</PageHeading>
    </PageHeader>
    {contract.selectedLocationId ? (
      <PageContent>
        <Row>
          <Section>
            <Well>
              {contract.isFullyLoaded && (
                <Form
                  contract={contract}
                  onAdd={s =>
                    onAdd(s).then(result => {
                      // Fetch tab badge numbers, network-only to force a call to the server
                      client.query({
                        query: TABS_COUNT_QUERY,
                        variables: {
                          contractId: contract.id,
                        },
                        fetchPolicy: 'network-only',
                      });
                      return result;
                    })
                  }
                />
              )}
            </Well>
          </Section>
          <Section>
            <ShipmentList
              shipments={batch.items || []}
              onRemove={s =>
                remove(s.id).then(result => {
                  // Fetch tab badge numbers, network-only to force a call to the server
                  client.query({
                    query: TABS_COUNT_QUERY,
                    variables: {
                      contractId: contract.id,
                    },
                    fetchPolicy: 'network-only',
                  });
                  return result;
                })
              }
            />
          </Section>
        </Row>

        <Row>
          <Col>
            <BottomNav>
              {batch.id && (
                <SummaryButton $as={Link} to={`${match.url}/summary`}>
                  View summary
                </SummaryButton>
              )}
            </BottomNav>
          </Col>
        </Row>
      </PageContent>
    ) : (
      <PageContent>
        <Query query={LOCATIONS_QUERY} variables={{ contractId: parseInt(contract.id) }}>
          {({ data }) => (
            <LocationSelector
              contract={contract}
              locations={data && data.contract.locations}
              selectLocation={selectLocation}
            />
          )}
        </Query>
      </PageContent>
    )}
  </div>
);

const Section = withStyle(Col, {
  minHeight: '400px',
});

const Well = styled('div', {
  height: '100%',
  padding: '20px',
  marginBottom: '20px',
  backgroundColor: '#f5f5f5',
  border: '1px solid #E3E3E3',
  boxShadow: 'inset 0 1px 1px rgba(0,0,0,.05)',
});

const BottomNav = withStyle(RightAlign, {
  width: '100%',
  padding: '1em 0',
});

const SummaryButton = styled('a', {
  ...makeButtonStyle({ primary: true }),
  ':hover': {
    '-webkit-text-stroke': 'initial',
    color: 'white',
    textDecoration: 'none',
    backgroundColor: style.primaryDarker,
  },
});

export default withApollo(RegisterView);
