import React from 'react';
import { Query } from 'react-apollo';
import { gql } from 'apollo-boost';
import { styled } from 'styletron-react';
import { connect } from 'react-redux';
import Notification from '../../../utils/promiseNotification';
import ShipmentTypeToggle from './ShipmentTypeToggle';
import ParcelForm from './ParcelForm';
import shipmentType from '../type';
import UserPicker from '../../../components/UserPicker';
import PersonWithMail from '../../../components/PersonWithMail';
import CustomField from '../../../components/CustomField';
import Icon from '../../../components/Icons/ArrowRight';
import { CheckboxField as Checkbox } from '../../../components/Field';
import StatefulButton from '../../../components/StatefulButton.js';
import SelectPrinter from '../../../components/Print/SelectPrinter.js';
import { getDefaultPrinter, getPrinterStatus, printShipments as print } from '../../../components/Print/printing';
import WithStickerConfiguration from '../../stickerConfiguration/WithStickerConfiguration';
import Add from '../../serviceUsers/Add';
import ReactModal from 'react-modal';
import { searchUserById } from '../../serviceUsers/searchUsers';
import LocationSelector from '../../../components/LocationSelector';

const USER_QUERY = gql`
  query ShipmentFormServiceUser($contractId: Int!, $serviceUserId: Int!) {
    contract(id: $contractId) {
      id
      serviceUser(id: $serviceUserId) {
        id
        letterLocation {
          id
          name
        }
        parcelLocation {
          id
          name
        }
        customFields {
          key
          value
        }
        assignee {
          firstName
          lastName
          email
        }
      }
    }
  }
`;

const buttonAsLink = {
  backgroundColor: 'transparent',
  textDecoration: 'underline',
  border: 'none',
  color: '#E78125',
  cursor: 'pointer',
};

const defaultState = {
  type: shipmentType.letter,
  user: undefined,
  parcelInfo: {
    referenceNumber: '',
    forwarder: '',
    numberOfPackages: 1,
    deviation: false,
    deviationNote: '',
  },
  requirePin: true,
  customFields: {},
  selectedPrinter: undefined,
  printerStatus: undefined,
  isLoadingShipment: false,
  showAddRecipientModal: false,
};

const modalStyle = {
  content: {
    position: 'absolute',
    top: '40%',
    bottom: 'auto',
    left: '50%',
    maxWidth: '500px',
    transform: 'translate(-50%, -50%)',
  },
};

ReactModal.setAppElement('#root');

class ShipmentForm extends React.Component {
  defaultInput = undefined;
  constructor(props) {
    super(props);
    this.state = defaultState;
  }

  onTypeChange(type) {
    this.setState({
      type,
    });
  }
  onUserChange(user) {
    this.setState({
      user,
    });
  }
  onAdd(e) {
    e.preventDefault();
    const { user, type, requirePin, parcelInfo, selectedPrinter } = this.state;
    const { contract, onAdd, stickerConfiguration } = this.props;

    this.setState({ isLoadingShipment: true });

    const value = {
      ...this.state,
      parcelInfo: type === shipmentType.parcel ? parcelInfo : {},
      currentLocationId: contract.selectedLocationId,
      deliveryLocationId: type === 0 ? user.letterLocation.id : user.parcelLocation.id,
      requirePin: user.parcelLocation.enablePinCodes && type === shipmentType.parcel && requirePin,
    };
    onAdd(value)
      .then(batch => {
        this.setState({
          isLoadingShipment: false,
        });
        if (selectedPrinter) {
          let shipment = batch.items[0]; // Latest shipment is now first in list SF-344
          return print(selectedPrinter, [shipment], stickerConfiguration);
        }
        return batch;
      })
      .then(() => {
        this.setState(prevState => ({
          ...defaultState,
          selectedPrinter,
          type: value.type,
          printerStatus: prevState.printerStatus,
          customFields: Object.keys(prevState.customFields).reduce(
            (fields, field) => ({
              ...fields,
              [field]: '', // Needed to actually reset the custom field fields. Otherwise created issues with inputs showing values that didn't match the state values
            }),
            {}
          ),
        }));
      })
      .catch(error => {
        this.setState({
          isLoadingShipment: false,
        });
        Notification.error(error.error);
      });

    this.defaultInput?.focus();
  }
  onParcelInfoChange(parcelInfo) {
    this.setState({ parcelInfo });
  }
  onCustomFieldChange(field, value) {
    this.setState(state => ({
      customFields: {
        ...state.customFields,
        [field]: value,
      },
    }));
  }
  onPrinterChange(selectedPrinter) {
    this.setState({ selectedPrinter });
    getPrinterStatus(selectedPrinter).then(printerStatus => this.setState({ printerStatus }));
  }
  onCheckboxChange(e) {
    this.setState({
      [e.target.name]: e.target.checked,
    });
  }
  componentDidMount() {
    const { contract } = this.props;
    if (contract.hasLetters && !contract.hasParcels) {
      this.onTypeChange(shipmentType.letter);
    }
    if (!contract.hasLetters && contract.hasParcels) {
      this.onTypeChange(shipmentType.parcel);
    }

    getDefaultPrinter()
      .then(selectedPrinter => {
        this.setState({ selectedPrinter });
        return selectedPrinter;
      })
      .then(printer =>
        getPrinterStatus(printer)
          .then(printerStatus => {
            this.setState({ printerStatus });
          })
          .catch(printerStatus => this.setState({ printerStatus }))
      )
      .catch(() => this.setState({ printerStatus: 'No printer(s) found' }));
  }

  showAddRecipientModal(e) {
    e.preventDefault();
    this.setState({
      showAddRecipientModal: true,
    });
  }

  closeAddRecipientModal(e) {
    e.preventDefault();
    this.setState({
      showAddRecipientModal: false,
    });
  }

  setServiceUserAsRecipient(id) {
    const { contract, searchUserById } = this.props;

    searchUserById(id, contract.id)
      .then(result => result.json())
      .then(user => {
        this.setState({
          showAddRecipientModal: false,
        });
        this.onUserChange(user);
      });
  }

  onChangeDeliveryLocation(location) {
    const { user } = this.state;
    user.letterLocation = location;
    user.parcelLocation = location;

    this.onUserChange(user);
  }

  translateOptionIdToValue(selectKey, optionId) {
    const { serviceUserFields } = this.props.contract;

    var select = serviceUserFields.find(f => f.name === selectKey);
    var selectOption = select.options.find(o => o.id === optionId);
    if (!selectOption) {
      console.error(`Unable to find value for ${optionId} in ${selectKey} options`, 'error');
      return '';
    } else {
      return selectOption.value;
    }
  }

  render() {
    const {
      user,
      type,
      requirePin,
      isLoadingShipment,
      selectedPrinter,
      printerStatus,
      parcelInfo,
      showAddRecipientModal,
    } = this.state;
    const { contract } = this.props;
    const isValid = !!user;

    const customFieldGroup = customFields =>
      customFields
        .filter(field => !field.disabled)
        .map(field => (
          <CustomField
            label={field.name}
            type={field.type}
            options={field.options}
            required={field.required}
            key={field.name}
            value={this.state.customFields[field.name]}
            onChange={value => this.onCustomFieldChange(field.name, value)}
          />
        ));

    return (
      <Query
        query={USER_QUERY}
        skip={!isValid}
        variables={{
          contractId: contract && parseInt(contract.id),
          serviceUserId: user && parseInt(user.id),
        }}
        notifyOnNetworkStatusChange={true}
      >
        {({ data, loading, error }) => {
          const serviceUser = data && data.contract && data.contract.serviceUser;

          if (error) {
            console.log('ApolloError->USER_QUERY:', error);
          }

          return (
            <div>
              <ReactModal isOpen={showAddRecipientModal} style={modalStyle}>
                <Add
                  onClose={e => this.closeAddRecipientModal(e)}
                  setServiceUserAsRecipient={id => this.setServiceUserAsRecipient(id)}
                />
              </ReactModal>
              {/* Recipient and the button to open the modal "Add recipient" needs to be outside the form
                  oterwise the automatic send shipment (on keyboard Return) will open the modal window
              */}
              <strong>Recipient</strong>
              <button style={buttonAsLink} onClick={e => this.showAddRecipientModal(e)}>
                Add new
              </button>
              <form
                onSubmit={e => {
                  this.onAdd(e);
                  this.setState(defaultState);
                }}
              >
                <UserPickerWrapper>
                  <UserPicker
                    inputRef={input => (this.defaultInput = input)}
                    onChange={changedUser => this.onUserChange(changedUser)}
                    user={user}
                  />
                </UserPickerWrapper>

                {user && (
                  <p>
                    <PersonWithMail {...user} bold />
                  </p>
                )}

                {user && (
                  <RecipientInfoList>
                    {serviceUser && serviceUser.assignee && (
                      <RecipientInfoListItem label="Assignee">
                        <PersonWithMail {...serviceUser.assignee} bold />
                      </RecipientInfoListItem>
                    )}
                    <LocationSelector
                      label="Delivery location"
                      contractId={contract.id}
                      allowUnset={false}
                      value={type === 0 ? user.letterLocation.id : user.parcelLocation.id}
                      onChange={location => this.onChangeDeliveryLocation(location)}
                    />

                    {serviceUser &&
                      serviceUser.customFields &&
                      serviceUser.customFields.length > 0 &&
                      serviceUser.customFields.map(field => {
                        var customField = contract.serviceUserFields.find(f => f.name === field.key && !f.disabled);

                        if (!customField) return null;
                        if (customField.type.toLowerCase() === 'select') {
                          var selectValue = this.translateOptionIdToValue(field.key, field.value);
                          field.value = selectValue;
                        }

                        return (
                          <RecipientInfoListItem label={field.key} key={field.key}>
                            {(field.value === 'true' && 'Yes') || (field.value === 'false' && 'No') || field.value}
                          </RecipientInfoListItem>
                        );
                      })}
                  </RecipientInfoList>
                )}

                {contract.hasLetters && contract.hasParcels && (
                  <ShipmentTypeToggle onChange={type => this.onTypeChange(type)} value={this.state.type} />
                )}

                {user && user.parcelLocation.enablePinCodes && type === shipmentType.parcel && (
                  <Checkbox
                    label="Require PIN"
                    name="requirePin"
                    checked={requirePin}
                    onChange={e => this.onCheckboxChange(e)}
                  />
                )}
                {type === shipmentType.parcel && (
                  <ParcelForm
                    onChange={info => this.onParcelInfoChange(info)}
                    value={parcelInfo}
                    contractId={contract.id}
                  />
                )}

                {customFieldGroup(contract.consignmentFields)}

                {type === shipmentType.letter && customFieldGroup(contract.letterFields)}

                {type === shipmentType.parcel && customFieldGroup(contract.parcelFields)}

                <ButtonRow>
                  <SelectPrinter
                    selectedPrinter={selectedPrinter}
                    onChange={printer => this.onPrinterChange(printer)}
                    printerStatus={printerStatus}
                  />
                  <StatefulButton
                    type="submit"
                    disabled={!isValid || !serviceUser || !data || loading}
                    isLoading={isLoadingShipment}
                    primary
                  >
                    <span>
                      Register Shipment <Icon />
                    </span>
                  </StatefulButton>
                </ButtonRow>
              </form>
            </div>
          );
        }}
      </Query>
    );
  }
}

const ButtonRow = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginTop: '20px',
});

const UserPickerWrapper = styled('div', {
  marginBottom: '20px',
});

const RecipientInfoList = styled('ul', {
  listStyle: 'none',
  padding: '0',
});

const RecipientInfoListItem = ({ label, children }) => (
  <li>
    {label}: {children}
  </li>
);

const mapDispatchToProps = {
  searchUserById,
};

const ShipmentFormWithStickers = props => {
  return (
    <WithStickerConfiguration contractId={props.contract.id}>
      {({ configuration }) => <ShipmentForm {...props} stickerConfiguration={configuration} />}
    </WithStickerConfiguration>
  );
};

export default connect(
  null,
  mapDispatchToProps
)(ShipmentFormWithStickers);
