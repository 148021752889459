import React, { Component } from 'react';
import merge from 'lodash.merge';

const UserDataContext = React.createContext();

const userDataKey = 'userdata';
const oldLegacyKey = 'selectedContract';
const legacyKey = 'selectedContractId';
const legacyBatchKey = 'application';

export function getUserData() {
  // Get Legacy LocalStorage
  const oldLegacyData = JSON.parse(localStorage.getItem(oldLegacyKey));
  const legacyData = JSON.parse(localStorage.getItem(legacyKey));
  const legacyBatch = JSON.parse(localStorage.getItem(legacyBatchKey));
  const userData = JSON.parse(localStorage.getItem(userDataKey)) || {
    main: {},
  };

  if (!userData.main) {
    userData.main = {};
  }

  const legacyContract = legacyData ? legacyData.contractId : oldLegacyData;
  const legacyLocation = legacyData ? legacyData.locationId : null;

  return {
    batchNotifications: legacyBatch ? legacyBatch.batchNotifications : undefined,
    ...userData,
    main: {
      currentContract: userData.main.currentContract || legacyContract || undefined,
      currentLocation: userData.main.currentLocation || legacyLocation || undefined,
    },
  };
}
export function setUserData(data) {
  // Get Legacy LocalStorage
  const oldLegacyData = JSON.parse(localStorage.getItem(oldLegacyKey));
  const legacyData = JSON.parse(localStorage.getItem(legacyKey));
  const legacyBatch = JSON.parse(localStorage.getItem(legacyBatchKey));
  const userData = getUserData();
  if (oldLegacyData) localStorage.removeItem(oldLegacyKey);
  if (legacyData) localStorage.removeItem(legacyKey);
  if (legacyBatch) localStorage.removeItem(legacyBatchKey);

  localStorage.setItem(userDataKey, JSON.stringify(merge(userData, data)));
}

export class Provider extends Component {
  state = {
    data: getUserData(),
  };

  componentDidMount() {
    this.setData(this.state.data);
  }

  setData(data) {
    setUserData(data);
    this.setState({ data: merge(this.state.data, data) });
  }
  render() {
    const { data } = this.state;
    const { children } = this.props;
    return (
      <UserDataContext.Provider
        value={{
          data,
          setData: data => this.setData(data),
        }}
      >
        {children}
      </UserDataContext.Provider>
    );
  }
}

export const Consumer = UserDataContext.Consumer;
