import React, { Component } from 'react';
import moment from 'moment';
import { styled, withStyle } from 'styletron-react';
import scanner from './scanner';
import { SET_CURRENT_LOCATION_MUTATION } from '../graph';
import { LOCATIONS_QUERY } from '../../../features/locations/graph';
import Button from '../../../components/Button';
import { Query, withApollo } from 'react-apollo';

// There's some weirdness with the scanner and detecting the format of a qr code string.
// On a mac with swedish (pro) layout it replaces / in a uri with -.
// Using english layout scans a correct uri.
const splitTokens = ['/', '-'];

const tryParseLocation = maybeId => {
  if (maybeId.indexOf('http') === 0) {
    if (maybeId.indexOf('locations') >= 0) {
      for (var i = 0; i < splitTokens.length; i++) {
        const split = maybeId.split(splitTokens[i]);
        if (split.length > 1) return split[split.length - 1];
      }
    }
  }
};

const Tree = styled('ul', {
  paddingLeft: '1.5rem',
});

const TreeButton = styled('button', {
  padding: '0.25rem',
});
const LocationTree = withStyle(Tree, {
  marginTop: '1rem',
});

const Location = ({ selectLocation, ...location }) => (
  <li>
    <TreeButton $as={Button} link onClick={() => selectLocation(location.id)}>
      {location.name}
    </TreeButton>
    {location.locations && (
      <Tree>
        {location.locations.map(childLocation => (
          <Location key={childLocation.id} {...childLocation} selectLocation={selectLocation} />
        ))}
      </Tree>
    )}
  </li>
);

const LocationSelector = ({ locations, selectLocation }) => (
  <LocationTree>
    {locations && locations.map(l => <Location key={l.id} {...l} selectLocation={selectLocation} />)}
  </LocationTree>
);

const findLocation = (location, locationId) => {
  if (location.id === locationId) return location;
  if (location.locations) {
    for (var i = 0; i < location.locations.length; i++) {
      const child = findLocation(location.locations[i], locationId);
      if (child) return child;
    }
  }
};

const ActionButton = styled('button', {
  marginLeft: 0,
});

const Confirm = ({ locations, selectedId, onConfirm }) => {
  if (!selectedId) return null;
  const location = findLocation({ locations }, selectedId);
  if (!location) return <span />;
  return (
    <ActionButton $as={Button} primary onClick={() => onConfirm()}>
      Move shipments to {location.name}
    </ActionButton>
  );
};

const Base = styled('div', {
  display: 'flex',
  flexDirection: 'column',
});

const Content = styled('div', {
  flexGrow: 1,
  overflow: 'auto',
  height: '1em',
});

class MoveShipment extends Component {
  state = {
    selectedLocationId: undefined,
  };
  componentDidMount() {
    this.scannerSubscription = scanner().subscribe(scanResult => {
      const id = tryParseLocation(scanResult);
      if (!id) return;
      this.setState({ selectedLocationId: +id });
    });
  }
  componentWillUnmount() {
    this.scannerSubscription.unsubscribe();
  }

  moveShipments(client) {
    const { shipments, onMoved } = this.props;

    client
      .mutate({
        mutation: SET_CURRENT_LOCATION_MUTATION,
        variables: {
          input: {
            consignments: Object.keys(shipments)
              .map(number => shipments[number])
              .map(s => ({ id: s.id, locationId: this.state.selectedLocationId })),
            eventTime: moment(new Date()).format(),
          },
        },
      })
      .then(result => {
        const shipments = result.data.consignmentSetCurrentLocation.reduce(
          (acc, curr) => ({
            ...acc,
            [curr.consignmentNumber]: curr,
          }),
          {}
        );
        onMoved(shipments);
      });
  }

  render() {
    const { shipments, contractId, client } = this.props;
    const { selectedLocationId } = this.state;
    return (
      <Query query={LOCATIONS_QUERY} variables={{ contractId: parseInt(contractId) }}>
        {({ data }) => (
          <Base>
            <Content>
              <div>
                Move {shipments.length} shipment{shipments.length > 1 && 's'} by scanning a location code or by
                selecting a location below.
              </div>
              {data && (
                <LocationSelector
                  locations={data.contract.locations}
                  selectLocation={id => this.setState({ selectedLocationId: +id })}
                />
              )}
            </Content>
            {data && (
              <Confirm
                locations={data.contract.locations}
                selectedId={selectedLocationId}
                onConfirm={() => this.moveShipments(client)}
              />
            )}
          </Base>
        )}
      </Query>
    );
  }
}

export default withApollo(MoveShipment);
