import React from 'react'
import Button from '../components/Button'
import store from '../store';
import style from '../style';

const axios = require('axios');
const buttonStyle = {
    padding: '8px 20px',
    border: style.baseBorder,
    boxShadow: style.baseShadow,
    lineHeight: '18px',
    transition: 'opacity 0.2s ease-in-out',
    marginLeft: '1rem',
    color: 'Grey',
    ':first-child': {
        marginLeft: 0,
    },
    ':hover': {
        backgroundColor: style.darkDarker,
        color: '#fff',
    },
    ':active': {
        backgroundColor: style.darkDarker,
        color: '#fff',
    },
    ':focus': {
        backgroundColor: style.darkDarker,
        color: '#fff',
        outline: 'none',
    }
};


class ImageUpload extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            file: null,
            images: []
        };

        this.onFormSubmit = this.onFormSubmit.bind(this);
        this.onChange = this.onChange.bind(this);

    }
    componentDidMount() {
        const { id } = this.props;
        this.onLoadImages(id);
    }

    onLoadImages(id) {
        fetch(`${process.env.REACT_APP_API_URI}/api/images/${id}/list`)
            .then(res => res.json())
            .then((result) => {
                    this.setState({
                        isLoaded: true,
                        images: result.images
                    });
                },
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            );
    }

    onFormSubmit(e) {
        const { id } = this.props;
        e.preventDefault();
        const formData = new FormData();
        formData.append('files', this.state.file);
        const config = {
            headers: {
                'content-type': 'multipart/form-data',
                'Authorization': `Bearer ${store.getState().oidc.user.access_token}`
                }
            }
        axios
            .post(`${process.env.REACT_APP_API_URI}/api/images/${id}/deviation`, formData, config)
            .then((response) => {
                this.onLoadImages(id);
            })
            .catch((error) => { });
    };

    onChange(e) {
        this.setState({ file: e.target.files[0] });
    }

    render() {
        const { images } = this.state;
        return (
            <form onSubmit={this.onFormSubmit}>
                <h3>Uploaded images</h3>
                <hr />
                {
                    images.map(image => (
                        <>
                            <img width="140" style={{ marginRight: '5px' }} src={image.uri} alt=''/>
                        </>
                    ))
                }
                <div style={{ marginTop: '20px' }}>
                    <input type="file" name="files" id="file" onChange={this.onChange} style={buttonStyle} accept="image/x-png,image/gif,image/jpeg" />
                    <Button type="submit" >Upload</Button>
                </div>
                <hr />
            </form>
        )
    }
}

export default ImageUpload
