import React from 'react';
import { Query } from 'react-apollo';
import { LOCATIONS_QUERY } from '../features/locations/graph';
import { Select } from './Field';

const flattenLocations = (locations, level = 0) =>
  (locations || []).reduce(
    (acc, location) => acc.concat([{ ...location, level }]).concat(flattenLocations(location.locations, level + 1)),
    []
  );

const locationLevelChars = '-------';

const Option = ({ location, filter, level }) => {
  if (filter && !filter(location)) return null;
  return (
    <React.Fragment>
      <option key={location.id} value={location.id}>
        {locationLevelChars.substring(0, level)} {location.name}
      </option>
      {location.locations &&
        location.locations
          .filter(child => child.status !== 'InActive')
          .map(child => <Option key={child.id} location={child} filter={filter} level={level + 1} />)}
    </React.Fragment>
  );
};

export default ({ contractId, allowUnset, onChange, filter, disabled, value, ...inputProps }) => (
  <Query query={LOCATIONS_QUERY} variables={{ contractId: parseInt(contractId) }}>
    {({ data, loading, error }) => {
      const flattenedLocations = flattenLocations(data && data.contract.locations);
      const wrappedOnChange = e => {
        const location = flattenedLocations.filter(l => l.id === +e.target.value)[0];
        if (onChange) onChange(location);
      };
      return (
        <Select {...inputProps} onChange={wrappedOnChange} disabled={!data || loading || disabled} value={value || ''}>
          {data && allowUnset && <option value={undefined}>- Select location -</option>}
          {data &&
            data.contract.locations
              .filter(location => location.status !== 'InActive')
              .map(location => <Option key={location.id} location={location} level={0} filter={filter} />)}
        </Select>
      );
    }}
  </Query>
);
