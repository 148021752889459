import React from 'react';
import { styled, withStyle } from 'styletron-react';
import style from '../style';

const List = styled('ul', {
  listStyle: 'none',
  padding: 0,
  border: `1px solid ${style.light}`,
});

export const BaseLi = styled('li', {
  border: '1px solid transparent',
  borderTop: `1px solid ${style.light}`,
  ':first-child': {
    borderTopColor: 'transparent',
  },
});

export const Li = styled('li', {
  padding: '10px 8px',
  listStyleType: 'none',
  borderBottom: '1px solid #CCCCCC',
  ':last-child': {
    borderBottom: 'none',
  },
});

export const ListItem = withStyle(BaseLi, {
  padding: style.basePadding,
});

export const makeListItem = Component => {
  const StyledComponent = styled(Component, {
    display: 'block',
    padding: style.basePadding,
  });
  return props => (
    <BaseLi>
      <StyledComponent {...props} />
    </BaseLi>
  );
};

export default List;
