import { gql } from 'apollo-boost';
export const STATISTICS_QUERY = gql`
  query Statistics {
    statistics {
      punchcard {
        type
        weekday
        hour
        count
      }
    }
  }
`;

export const CONTRACT_STATISTICS_QUERY = gql`
  query ContractStatistics($contractId: Int!) {
    contract(id: $contractId) {
      id
      statistics {
        punchcard {
          type
          weekday
          hour
          count
        }
      }
    }
  }
`;
