import React from 'react';
import Field, { CheckboxField as Checkbox } from '../../components/Field';
import Button from '../../components/Button';
import withForm from '../../components/withForm';
import { RightAlign } from '../../components/Layout';
import LocationSelector from '../../components/LocationSelector';
import { ErrorMessage } from '../../components/MiscPresentational';

const LocationForm = ({
  values,
  onChange,
  onValuesChange,
  onSubmit,
  isSubmitting,
  location,
  disabled,
  currentContractId,
  disableRefId,
  errors,
  actions,
}) => {
  const contractChangeError =
    values.contract && values.contract.id !== currentContractId && `This location belongs to ${values.contract.name}`;

  const isValidToSubmit = !isSubmitting && !disabled && !(!values.name || !values.referenceId) && !contractChangeError;
  return (
    <form>
      <Field
        label="Name"
        name="name"
        value={values.name || ''}
        onChange={onChange}
        disabled={disabled || isSubmitting}
        required
      />
      <Field
        label="Description"
        name="description"
        value={values.description || ''}
        onChange={onChange}
        disabled={disabled || isSubmitting}
      />
      <Field
        label="Reference Id"
        name="referenceId"
        value={values.referenceId || ''}
        onChange={onChange}
        disabled={disabled || isSubmitting || disableRefId}
        required
        error={errors && errors.item && errors.item.referenceId}
      />
      <LocationSelector
        label="Parent Location"
        name="parentReferenceId"
        value={values.parentLocation ? values.parentLocation.id : undefined}
        onChange={parentLocation => onValuesChange({ parentLocation })}
        disabled={disabled || isSubmitting}
        filter={filterLocation => !location || filterLocation.id !== location.id}
        contractId={currentContractId}
        allowUnset={true}
      />
      <Checkbox
        label="Enable PIN codes"
        name="enablePinCodes"
        onChange={onChange}
        checked={values.enablePinCodes}
        disabled={disabled || isSubmitting}
      />
      <Checkbox
        label="Requires identity verification"
        name="requiresIdentityVerification"
        onChange={onChange}
        checked={values.requiresIdentityVerification}
        disabled={disabled || isSubmitting}
      />
      <RightAlign>
        <ErrorMessage>{contractChangeError}</ErrorMessage>
        {actions}
        <Button primary disabled={!isValidToSubmit} onClick={() => onSubmit()}>
          Save
        </Button>
      </RightAlign>
    </form>
  );
};

export default withForm({
  mapPropsToValues: props => props.location,
  mapPropsToSubmit: props => props.save,
})(LocationForm);
