import React from 'react';
import { connect } from 'react-redux';
import { send } from './action-creators';
import { toQuery, fromQuery } from '../../utils/uri';
import { Consumer as UserDataConsumer } from '../../components/UserData';
import debounce from 'lodash.debounce';
import { Query } from 'react-apollo';
import { gql } from 'apollo-boost';

const BATCH_NOTIFICATIONS_QUERY = gql`
  query BatchNotifications($contractId: Int!, $after: String, $before: String, $first: Int, $locationId: Int) {
    contract(id: $contractId) {
      id
      unsentNotifications
      batchNotifications(after: $after, before: $before, first: $first, locationId: $locationId) {
        totalCount
        pageInfo {
          startCursor
          endCursor
          hasNextPage
          hasPreviousPage
        }
        edges {
          node {
            batchId
            registeredDate
            currentLocations {
              currentLocation {
                id
                name
              }
              consignments {
                id
                consignmentNumber
                deliveryLocation {
                  id
                  name
                }
                receiverUser {
                  firstName
                  lastName
                  email
                }
                registeredDate
              }
            }
          }
        }
      }
    }
  }
`;

class WithBatchNotifications extends React.Component {
  componentDidMount() {
    this.debounceQuery = debounce((query, history, match, queryParams) => {
      history.replace(
        `${match.path}?${toQuery({
          query,
          ...(queryParams.first && {
            first: queryParams.first,
          }),
        })}`
      );
    }, 300);
  }
  render() {
    const { contractId, send, children, location } = this.props;
    const queryParams = location ? fromQuery(location.search) : {};

    return (
      <UserDataConsumer>
        {({ data: { batchNotifications = {} } }) => {
          const locationId = batchNotifications.filters ? batchNotifications.filters.location : null;
          return (
            <Query
              query={BATCH_NOTIFICATIONS_QUERY}
              variables={{
                ...queryParams,
                contractId: parseInt(contractId),
                locationId: parseInt(locationId),
              }}
              // Until we mutatate in the new consignments we need to always get the updated list from the server
              fetchPolicy="network-only"
            >
              {({ data, refetch }) => {
                return children({
                  data,
                  send: ids =>
                    send(ids)
                      .then(result => {
                        return result;
                      })
                      .then(() => refetch()),
                });
              }}
            </Query>
          );
        }}
      </UserDataConsumer>
    );
  }
}

const mapStateToProps = state => ({ contractId: state.contracts.selected.id });
const mapDispatchToProps = { send };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WithBatchNotifications);
