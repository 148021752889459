import React, { Fragment } from 'react';
import Form from '../Form';
import Notification from '../../../utils/promiseNotification';
import { connect } from 'react-redux';
import { PageHeader, PageHeading, PageContent } from '../../../components/Page';
import Button from '../../../components/Button';
import GraphQLProvider from '../../../components/GraphQLProvider';
import { Query, Mutation } from 'react-apollo';
import { gql } from 'apollo-boost';

const USER_QUERY = gql`
  query ServiceUserForm($contractId: Int!, $serviceUserId: Int!) {
    contract(id: $contractId) {
      id
      serviceUser(id: $serviceUserId) {
        id
        firstName
        lastName
        email
        phoneNumber
        referenceId
        status
        contract {
          id
          name
        }
        letterLocation {
          id
          name
          referenceId
        }
        parcelLocation {
          id
          name
          referenceId
        }
        customFields {
          key
          value
        }
        assignee {
          id
          firstName
          lastName
          email
        }
        isAssigneeFor {
          id
          firstName
          lastName
          email
        }
        isAssignee
      }
    }
  }
`;

const DELETE_USER_MUTATION = gql`
  mutation ServiceUserRemove($input: ServiceUserRemoveInputType!) {
    serviceUserRemove(input: $input) {
      id
      status
    }
  }
`;

class EditServiceUser extends React.Component {
  state = {
    deleteEnabled: false,
  };

  toggleEnableDelete = () =>
    this.setState(prevState => ({
      deleteEnabled: !prevState.deleteEnabled,
    }));

  handleUpdate = (values, refetch) => {
    const { updateServiceUser } = this.props;
    return updateServiceUser(values)
      .then(res => {
        refetch({ skipCache: true });
        return res;
      })
      .then(Notification.success('Recipient saved'))
      .catch(Notification.error('Error: Recipient not saved'));
  };

  handleDelete = deleteUser => {
    const { serviceUserId } = this.props;
    return deleteUser({ input: { id: serviceUserId } })
      .then(Notification.success('Recipient deleted'))
      .catch(Notification.error('Error: Deleting recipient failed'));
  };

  render() {
    const { contract, serviceUserId, disabled } = this.props;
    const { deleteEnabled } = this.state;

    return (
      <div>
        <PageHeader>
          <PageHeading>Edit Recipient</PageHeading>
        </PageHeader>
        <PageContent>
          <GraphQLProvider>
            <Query
              query={USER_QUERY}
              variables={{ contractId: parseInt(contract.id), serviceUserId: parseInt(serviceUserId) }}
            >
              {({ data, loading, error, refetch }) => {
                const wrongContract = data ? data.contract.serviceUser.contract.id !== contract.id : false;
                return (
                  <Mutation mutation={DELETE_USER_MUTATION} variables={{ input: { id: parseInt(serviceUserId) } }}>
                    {deleteUser => {
                      return (
                        <Form
                          serviceUser={
                            data
                              ? {
                                  ...data.contract.serviceUser,
                                  customFields: (data.contract.serviceUser
                                    ? data.contract.serviceUser.customFields
                                    : []
                                  ).reduce((acc, curr) => ({ ...acc, [curr.key]: curr.value }), {}),
                                }
                              : {}
                          }
                          save={values => this.handleUpdate(values, refetch)}
                          contract={contract}
                          disabled={disabled || loading || (data && data.contract.serviceUser.status === 'Inactive')}
                          enableAssigneeUserInput={data && !!!data.contract.serviceUser.isAssignee}
                          actions={
                            <Fragment>
                              {data && data.contract.serviceUser.status === 'Inactive' && (
                                <span>This user is deactivated</span>
                              )}
                              {data && data.contract.serviceUser.status === 'Active' && !wrongContract && (
                                <label>
                                  Check to enable delete button{' '}
                                  <input type="checkbox" value={deleteEnabled} onChange={this.toggleEnableDelete} />
                                </label>
                              )}
                              <Button
                                disabled={
                                  !data ||
                                  disabled ||
                                  !deleteEnabled ||
                                  wrongContract ||
                                  data.contract.serviceUser.status !== 'Active'
                                }
                                onClick={() => this.handleDelete(deleteUser)}
                                danger
                              >
                                Delete
                              </Button>
                            </Fragment>
                          }
                        />
                      );
                    }}
                  </Mutation>
                );
              }}
            </Query>
          </GraphQLProvider>
        </PageContent>
      </div>
    );
  }
}

export default connect((state, props) => ({
  serviceUserId: props.match.params.serviceUserId,
  contract: state.contracts.selected,
}))(EditServiceUser);
