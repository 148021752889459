import { gql } from 'apollo-boost';

export const SET_CURRENT_LOCATION_MUTATION = gql`
  mutation ConsignmentSetCurrentLocation($input: ConsignmentsSetCurrentLocationsType!) {
    consignmentSetCurrentLocation(input: $input) {
      id
      consignmentNumber
      currentLocation {
        id
        name
      }
    }
  }
`;
