import React from 'react';
import {
  PageHeader,
  PageHeading,
  PageContent,
  PageDescription,
  FilterRow,
  FilterCol,
  FilterLabel,
} from '../../components/Page';
import Button from '../../components/Button';
import { styled } from 'styletron-react';
import style from '../../style';
import WithBatchNotifications from './WithBatchNotifications';
import LocationSelector from '../../components/LocationSelector';
import Notification from '../../utils/promiseNotification';
import { Consumer as UserDataConsumer } from '../../components/UserData';
import { formatDate } from '../../utils/dateUtil';
import { TableWrapper, Table, TableHeader, TableHeaderCell, TableRow, TableCell } from '../../components/Table';
import { GraphPagination as Pagination } from '../../components/Pagination';
import { TABS_COUNT_QUERY } from '../shipment/index';
import { withApollo } from 'react-apollo';

const ActionButton = styled('div', {
  marginLeft: 'auto',
});

const defaultState = {
  batchGroupVisibility: {},
  selectedConsignments: {},
};

class BatchNotifications extends React.Component {
  state = defaultState;

  handleConsignmentChange(consignmentIds, shouldBeSelected) {
    this.setState(state => ({
      selectedConsignments: consignmentIds.reduce(
        (acc, id) => ({
          ...acc,
          [id]: shouldBeSelected,
        }),
        state.selectedConsignments
      ),
    }));
  }

  toggleBatchGroupVisibility(batchId) {
    this.setState(state => ({
      batchGroupVisibility: {
        ...state.batchGroupVisibility,
        [batchId]: !!!state.batchGroupVisibility[batchId],
      },
    }));
  }

  handleSend = () => {
    const { selectedConsignments } = this.state;
    const { send, contractId, client } = this.props;

    send(
      Object.keys(selectedConsignments)
        .filter(id => selectedConsignments[id])
        .map(id => id)
    )
      .then(Notification.success('Notifications sent'))
      .then(result => {
        // Fetch tab badge numbers, network-only to force a call to the server
        client.query({
          query: TABS_COUNT_QUERY,
          variables: {
            contractId,
          },
          fetchPolicy: 'network-only',
        });

        return result;
      })
      .catch(Notification.error('Error occured: Notifications not sent'));

    this.setState({ selectedConsignments: {} });
  };

  hasAssignee = consignment =>
    consignment.assigneeUserEmail || consignment.assigneeUserFirstName || consignment.assigneeUserLastName;

  render() {
    const { selectedConsignments } = this.state;
    const { contractId, loaded, batches, match, history, location, pageInfo } = this.props;
    const disableActionBtn = !Object.keys(selectedConsignments).some(
      consignmentNumber => selectedConsignments[consignmentNumber]
    );
    return (
      <div>
        <PageHeader>
          <PageHeading>Unsent notifications</PageHeading>
          <ActionButton>
            <Button primary disabled={disableActionBtn} onClick={this.handleSend}>
              Send notifications
            </Button>
          </ActionButton>
        </PageHeader>
        <PageDescription>
          Select the entire batches or pick individual shipments and then click Send notifications to alert people that
          they have letters or parcels to pick up. This will also change the status on all selected letters to
          "Delivered".
        </PageDescription>

        <UserDataConsumer>
          {({ data: { batchNotifications = {} }, setData }) => {
            const locationId = batchNotifications.filters ? batchNotifications.filters.location : undefined;
            return (
              <PageContent>
                <FilterRow>
                  <FilterCol>
                    <FilterLabel title="Filter on location">
                      {loaded && (
                        <LocationSelector
                          contractId={contractId}
                          value={locationId || ''}
                          onChange={location =>
                            setData({
                              batchNotifications: {
                                filters: {
                                  location: location ? location.id : null,
                                },
                              },
                            })
                          }
                          allowUnset
                        />
                      )}
                    </FilterLabel>
                  </FilterCol>
                </FilterRow>
                <TableWrapper>
                  <Table>
                    <TableHeader background={style.primary} color="#fff">
                      <TableHeaderCell>Send</TableHeaderCell>
                      <TableHeaderCell>Item</TableHeaderCell>
                      <TableHeaderCell>Recipient</TableHeaderCell>
                      <TableHeaderCell>Delivery location</TableHeaderCell>
                      <TableHeaderCell>Date</TableHeaderCell>
                    </TableHeader>
                    <tbody>
                      {batches.map(b =>
                        b.currentLocations.map((currentLocation, locationIndex) => {
                          const groupId = `${b.batchId}${
                            currentLocation.currentLocation ? `-${currentLocation.currentLocation.id}` : ''
                          }`;
                          const allSelected = currentLocation.consignments.every(
                            consignment => selectedConsignments[consignment.id]
                          );
                          return (
                            <React.Fragment key={locationIndex}>
                              <TableRow $clickable onClick={() => this.toggleBatchGroupVisibility(groupId)}>
                                <TableCell>
                                  <input
                                    id={b.batchId}
                                    type="checkbox"
                                    checked={allSelected}
                                    onChange={e =>
                                      this.handleConsignmentChange(
                                        currentLocation.consignments.map(c => c.id),
                                        e.target.checked
                                      )
                                    }
                                    onClick={e => {
                                      e.stopPropagation();
                                    }}
                                  />
                                </TableCell>
                                <TableCell colSpan="3">
                                  <b>
                                    Batch {b.batchId}
                                    {currentLocation.currentLocation
                                      ? ` (${currentLocation.currentLocation.name})`
                                      : ''}
                                  </b>
                                </TableCell>
                                <TableCell>{formatDate(b.registeredDate)}</TableCell>
                              </TableRow>
                              {this.state.batchGroupVisibility[groupId]
                                ? currentLocation.consignments
                                    .sort((a, b) =>
                                      a.registeredDate > b.registeredDate
                                        ? -1
                                        : a.registeredDate < b.registeredDate
                                        ? 1
                                        : 0
                                    )
                                    .map(c => (
                                      <TableRow key={c.id}>
                                        <TableCell>
                                          <input
                                            id={c.id}
                                            type="checkbox"
                                            checked={selectedConsignments[c.id]}
                                            onChange={() =>
                                              this.handleConsignmentChange([c.id], !selectedConsignments[c.id])
                                            }
                                            onClick={e => {
                                              e.stopPropagation();
                                            }}
                                          />
                                        </TableCell>
                                        <TableCell
                                          title={
                                            this.hasAssignee(c) &&
                                            `Assigned to: ${c.assigneeUserFirstName} ${c.assigneeUserLastName}`
                                          }
                                        >
                                          {c.consignmentNumber}
                                          {this.hasAssignee(c) && <span>*</span>}
                                        </TableCell>
                                        {c.receiverUser && (
                                          <TableCell>
                                            {c.receiverUser.firstName} {c.receiverUser.lastName} {c.receiverUser.email}
                                          </TableCell>
                                        )}
                                        {!c.receiverUser && <TableCell>ANONYMIZED</TableCell>}
                                        <TableCell>{c.deliveryLocation.name}</TableCell>
                                        <TableCell>{formatDate(c.registeredDate)}</TableCell>
                                      </TableRow>
                                    ))
                                : []}
                            </React.Fragment>
                          );
                        })
                      )}
                    </tbody>
                  </Table>
                </TableWrapper>
                {loaded && (
                  <Pagination match={match} location={location} history={history} pageInfo={pageInfo} hidePageSize />
                )}
              </PageContent>
            );
          }}
        </UserDataConsumer>
      </div>
    );
  }
}

const WithBatchNotificationsAndClient = props => (
  <WithBatchNotifications {...props}>
    {({ data, send }) => {
      return (
        <BatchNotifications
          loaded={data}
          contractId={data ? data.contract.id : undefined}
          batches={data ? data.contract.batchNotifications.edges.map(edge => edge.node) : []}
          pageInfo={data ? data.contract.batchNotifications.pageInfo : null}
          send={send}
          {...props}
        />
      );
    }}
  </WithBatchNotifications>
);

export default withApollo(WithBatchNotificationsAndClient);
