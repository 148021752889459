import { ApolloClient, InMemoryCache, HttpLink, ApolloLink } from 'apollo-boost';

const authLink = token =>
  new ApolloLink((operation, forward) => {
    operation.setContext({
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return forward(operation);
  });

const httpLink = new HttpLink({ uri: `${process.env.REACT_APP_API_URI}/webgraph` });

function createClient(token) {
  return new ApolloClient({
    cache: new InMemoryCache(),
    link: ApolloLink.from([authLink(token), httpLink]),
  });
}

export default createClient;
