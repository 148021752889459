import fetch from '../../utils/fetch';

export const SERVICE_USER_FETCH = 'SERVICE_USER_FETCH';
export const SERVICE_USER_FETCH_SUCESS = 'SERVICE_USER_FETCH_SUCESS';

const mapUserToApiValues = values => ({
  ...values,
  ...(values.letterLocation &&
    values.letterLocation.referenceId && {
      letterLocationReferenceId: values.letterLocation.referenceId,
    }),
  ...(values.parcelLocation &&
    values.parcelLocation.referenceId && {
      parcelLocationReferenceId: values.parcelLocation.referenceId,
    }),
});

export const addServiceUser = (userProps, contractId) => (dispatch, getState) =>
  fetch('api/serviceuser', {
    method: 'POST',
    body: JSON.stringify({
      item: mapUserToApiValues(userProps),
      contractId,
    }),
  })(dispatch, getState);

export const updateServiceUser = values => (dispatch, getState) =>
  fetch(`api/serviceUser/${values.id}`, {
    method: 'PUT',
    body: JSON.stringify({
      contractId: values.contract.id,
      item: mapUserToApiValues(values),
    }),
  })(dispatch, getState);
