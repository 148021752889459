import React from 'react';
import Field, { CheckboxField as Checkbox, Select } from './Field';

export default ({ onChange, options, ...props }) =>
  props.type === 'Boolean' ? (
    <Checkbox
      {...props}
      onChange={e => onChange(e.target.checked)}
      checked={props.value && props.value !== 'false'}
      required={false} // It should not currently be possible to set a "forcable" true checkbox
    />
  ) : props.type === 'Select' ? (
    <Select {...props} onChange={e => onChange(e.target.value)}>
      <option value="">- Select option -</option>
      {(options || []).filter(option => option.active).map(option => (
        <option key={option.id} value={option.id}>
          {option.value}
        </option>
      ))}
    </Select>
  ) : (
    <Field {...props} onChange={e => onChange(e.target.value)} />
  );
