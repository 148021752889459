import React, { Fragment } from 'react';
import GraphQLProvider from '../../../components/GraphQLProvider';
import { Query } from 'react-apollo';

import { PageHeader, PageHeading, PageContent } from '../../../components/Page';
import List from '../../../components/List';
import Location from './Location';

import { LOCATIONS_QUERY } from '../graph';

const LocationsIndex = ({ contractId, match }) => (
  <GraphQLProvider>
    <Query query={LOCATIONS_QUERY} variables={{ contractId: parseInt(contractId) }}>
      {({ data }) => (
        <Fragment>
          <PageHeader>
            <PageHeading>Locations</PageHeading>
          </PageHeader>
          <PageContent>
            <List>
              {data &&
                data.contract.locations
                  .filter(location => location.status !== 'InActive')
                  .map(l => <Location key={l.id} level={0} {...l} match={match} />)}
            </List>
          </PageContent>
        </Fragment>
      )}
    </Query>
  </GraphQLProvider>
);

export default LocationsIndex;
