import React from 'react';
import Form from '../Form';
import Notification from '../../../utils/promiseNotification';
import { connect } from 'react-redux';
import { PageHeader, PageHeading, PageContent } from '../../../components/Page';

const AddLocation = ({ addLocation, contractId, disabled }) => (
  <div>
    <PageHeader>
      <PageHeading>Add Location</PageHeading>
    </PageHeader>
    <PageContent>
      <Form
        save={location =>
          addLocation(location)
            .then(Notification.success('Location added'))
            .catch(Notification.error('Error: Adding location failed'))}
        currentContractId={contractId}
        disabled={disabled}
      />
    </PageContent>
  </div>
);

export default connect(state => ({
  contractId: state.contracts.selected.id,
}))(AddLocation);
