import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { state as asyncState } from '../../utils/fetch';
import { load, deliver, remove, currentLocationChanged, patchDeviationNote, isDeviation, deleteImage, countDeviationImages } from './action-creators';
import { SET_CURRENT_LOCATION_MUTATION } from './graph';
import { Mutation } from 'react-apollo';

const ensureShipmentLoaded = (shipment, load) => {
  if (shipment.state === asyncState.notTriggered) {
    load(shipment.id);
  }
};

class WithShipment extends React.Component {
  componentDidMount() {
    ensureShipmentLoaded(this.props.shipment, this.props.load);
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    ensureShipmentLoaded(nextProps.shipment, nextProps.load);
  }

  setCurrentLocation = (locationId, setCurrentLocation) => {
    const { shipmentId, currentLocationChanged } = this.props;
    setCurrentLocation({
      variables: {
        input: {
          consignments: [{ id: shipmentId, locationId }],
          eventTime: moment(new Date()).format(),
        },
      },
    }).then(result => {
      currentLocationChanged(shipmentId, result.data.consignmentSetCurrentLocation[0].currentLocation);
      return result.data;
    });
  };

  render() {
    const { currentLocationChanged, children, ...props } = this.props;

    if (!props.shipment.hasLoadedOnce) {
      return <div>Loading shipment...</div>;
    }

    return (
      <Mutation mutation={SET_CURRENT_LOCATION_MUTATION}>
        {setCurrentLocation =>
          children({
            ...props,
            setCurrentLocation: locationId => this.setCurrentLocation(locationId, setCurrentLocation),
          })
        }
      </Mutation>
    );
  }
}

const mapStateToProps = (state, props) => ({
  shipment: state.shipment.items[props.shipmentId] || {
    id: props.shipmentId,
    state: asyncState.notTriggered,
  },
});

const mapDispatchToProps = {
  load,
  deliver,
  remove,
  currentLocationChanged,
  patchDeviationNote,
  isDeviation,
  deleteImage,
  countDeviationImages
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WithShipment);
