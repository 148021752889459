import React from 'react';
import Page, { PageHeading, PageHeader, PageContent, PageDescription } from '../../components/Page';
import ErrorBoundary from '../../components/ErrorBoundary';
import Punchcard from '../../components/statistics/Punchcard';
import StatisticType from '../../components/statistics/StatisticType';
import { STATISTICS_QUERY } from './graph';
import {
  Table,
  TableHeader,
  TableHeaderCell,
  TableBody,
  TableRow,
  TableCell,
  TableWrapper,
} from '../../components/Table';
import { formatDate } from '../../utils/dateUtil';
import GraphQLProvider from '../../components/GraphQLProvider';
import { Query } from 'react-apollo';
import { gql } from 'apollo-boost';

const NOTIFICATION_QUEUE_QUERY = gql`
  query ContractNotificationQueue {
    notificationQueue {
      id
      notificationTime
      inProgress
      retryCount
      consignments {
        id
        consignmentNumber
        lastReminderSent
        contract {
          name
        }
        receiverUser {
          id
          firstName
          lastName
          email
        }
        assigneeUser {
          id
          firstName
          lastName
          email
        }
      }
    }
  }
`;

export default () => (
  <ErrorBoundary>
    <Page>
      <PageHeader>
        <PageHeading>Statistics</PageHeading>
      </PageHeader>
      <PageDescription>
        <p>
          This shows the activity for all contracts in Smartflow. The two types are <StatisticType no={0} /> and{' '}
          <StatisticType no={1} />. A larger circle indicates more activity.
        </p>
        <p>The data presented are all shipments ever registered and delivered.</p>
      </PageDescription>
      <GraphQLProvider>
        <PageContent>
          <Query query={STATISTICS_QUERY}>
            {({ data }) => (!data ? null : <Punchcard punchcard={data.statistics.punchcard} />)}
          </Query>
        </PageContent>
        <PageHeader>
          <PageHeading>Notification queue</PageHeading>
        </PageHeader>
        <PageDescription>
          These are the shipments that are queued to be sent to their respective recipient at the time specified in the
          "Send at" column. A high retry count might indicate a problem with the shipment or the email of its recipient.
        </PageDescription>
        <PageContent>
          <Query query={NOTIFICATION_QUEUE_QUERY}>
            {({ data }) => (
              <TableWrapper style={{ overflow: 'hidden' }}>
                <Table>
                  <TableHeader>
                    <TableHeaderCell>Contract</TableHeaderCell>
                    <TableHeaderCell>Shipment number</TableHeaderCell>
                    <TableHeaderCell>Send at</TableHeaderCell>
                    <TableHeaderCell>Triggered at</TableHeaderCell>
                    <TableHeaderCell>Processing</TableHeaderCell>
                    <TableHeaderCell>Recipients</TableHeaderCell>
                    <TableHeaderCell>Retry count</TableHeaderCell>
                  </TableHeader>
                  <TableBody>
                    {data &&
                      data.notificationQueue &&
                      data.notificationQueue.map(queueItem =>
                        queueItem.consignments.map(consignment => (
                          <TableRow key={`${queueItem.id}-${consignment.id}`}>
                            <TableCell>{consignment.contract.name}</TableCell>
                            <TableCell>{consignment.consignmentNumber}</TableCell>
                            <TableCell>{formatDate(queueItem.notificationTime)}</TableCell>
                            <TableCell>{formatDate(consignment.lastReminderSent)}</TableCell>
                            <TableCell>{queueItem.inProgress ? 'Yes' : 'No'}</TableCell>
                            <TableCell>
                              <div>
                                Receiver: {consignment.receiverUser.firstName} {consignment.receiverUser.lastName} (
                                {consignment.receiverUser.email})
                              </div>
                              {consignment.assigneeUser && (
                                <div>
                                  Assignee: {consignment.assigneeUser.firstName} {consignment.assigneeUser.lastName} (
                                  {consignment.assigneeUser.email})
                                </div>
                              )}
                            </TableCell>
                            <TableCell>{queueItem.retryCount}</TableCell>
                          </TableRow>
                        ))
                      )}
                  </TableBody>
                </Table>
              </TableWrapper>
            )}
          </Query>
        </PageContent>
      </GraphQLProvider>
    </Page>
  </ErrorBoundary>
);
