import React, { Fragment } from 'react';
import GraphQLProvider from '../../../components/GraphQLProvider';
import { Query, Mutation } from 'react-apollo';
import { gql } from 'apollo-boost';
import Form from '../Form';
import Notification from '../../../utils/promiseNotification';
import { PageHeader, PageActionButton, PageHeading, PageContent } from '../../../components/Page';
import Button from '../../../components/Button';
import PrintButton from '../../../components/Print/PrintButton';
import { printLocations } from '../../../components/Print/printing';

const LOCATION_QUERY = gql`
  query LocationForm($contractId: Int!, $locationId: Int!) {
    contract(id: $contractId) {
      id
      location(id: $locationId) {
        id
        name
        description
        referenceId
        parentLocation {
          id
          referenceId
        }
        contract {
          id
          name
        }
        enablePinCodes
        requiresIdentityVerification
        isRemovable
        status
      }
    }
  }
`;

const DELETE_LOCATION_MUTATION = gql`
  mutation LocationRemove($input: LocationRemoveInputType!) {
    locationRemove(input: $input) {
      id
      name
      description
      referenceId
      parentLocation {
        id
        referenceId
      }
      contract {
        id
        name
      }
      enablePinCodes
      requiresIdentityVerification
      isRemovable
      status
    }
  }
`;

class Actions extends React.Component {
  state = {
    deleteEnabled: false,
  };
  toggleEnableDelete = () =>
    this.setState(state => ({
      deleteEnabled: !state.deleteEnabled,
    }));
  handleDelete = deleteLocation => {
    return deleteLocation({
      variables: {
        input: {
          id: this.props.location.id,
        },
      },
    })
      .then(Notification.success('Location deleted succesfully'))
      .catch(Notification.error('Error: Deleting location failed'));
  };

  render() {
    const { deleteEnabled } = this.state;
    const { location, disabled } = this.props;
    return (
      <GraphQLProvider>
        <Mutation mutation={DELETE_LOCATION_MUTATION}>
          {deleteLocation => (
            <Fragment>
              {location.isRemovable && (
                <label>
                  Check to enable delete button{' '}
                  <input type="checkbox" value={deleteEnabled} onChange={this.toggleEnableDelete} />
                </label>
              )}
              {location.status !== 'InActive' && !location.isRemovable && (
                <span style={{ maxWidth: '30%' }}>
                  It's not possible to remove this location as there's users connected to it or it has active child
                  locations.
                </span>
              )}

              <Button
                disabled={disabled || !deleteEnabled || !location.isRemovable}
                onClick={() => this.handleDelete(deleteLocation)}
                danger
              >
                Delete
              </Button>
            </Fragment>
          )}
        </Mutation>
      </GraphQLProvider>
    );
  }
}

export default class EditLocation extends React.Component {
  handleSave = (location, refetch) => {
    const { updateLocation } = this.props;
    return updateLocation(location)
      .then(res => {
        refetch();
        return res;
      })
      .then(Notification.success('Location saved'))
      .catch(Notification.error('Error: Saving location failed'));
  };
  render() {
    const { contractId, disabled, match } = this.props;
    const locationId = match.params.locationId;
    return (
      <GraphQLProvider>
        <Query
          query={LOCATION_QUERY}
          variables={{ contractId: parseInt(contractId), locationId: parseInt(locationId) }}
        >
          {({ data, loading, refetch }) => {
            const location = data && data.contract ? data.contract.location : undefined;
            return (
              <div>
                <PageHeader>
                  <PageHeading>Edit Location</PageHeading>
                  {location && (
                    <PageActionButton $as={PrintButton} onPrint={printer => printLocations(printer, [location])}>
                      Print QR-Code for location
                    </PageActionButton>
                  )}
                </PageHeader>
                <PageContent>
                  <Form
                    location={location}
                    save={location => this.handleSave(location, refetch)}
                    disabled={disabled || loading || !location || location.status === 'InActive'}
                    currentContractId={contractId}
                    disableRefId={true}
                    actions={location ? <Actions location={location} disabled={disabled} /> : null}
                  />
                </PageContent>
              </div>
            );
          }}
        </Query>
      </GraphQLProvider>
    );
  }
}
