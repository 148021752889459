import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { styled, withStyle } from 'styletron-react';
import CustomFields from './CustomFields/index';
import StickerConfiguration from './StickerConfiguration';
import ContractContext from '../ContractContext';
import Form from '../Form';
import Templates from '../Templates';
import Accordion, { AccordionPane } from '../../../components/Accordion';
import Button from '../../../components/Button';
import EmptyState from '../../../components/EmptyState';
import { ErrorMessage } from '../../../components/MiscPresentational';
import { PageContent, PageHeading, PageHeader } from '../../../components/Page';
import { Row, RightAlign, NoOffsetCol, Well } from '../../../components/Layout';
import style from '../../../style';
import Notification from '../../../utils/promiseNotification';
import { deleteContract, editContract, editCustomFields } from '../action-creators';
import GraphQLProvider from '../../../components/GraphQLProvider';
import { Mutation } from 'react-apollo';
import { gql } from 'apollo-boost';

const NoOffsetRow = withStyle(Row, {
  margin: 0,
});

const Wrapper = styled('div', {
  border: `solid 1px ${style.grey}`,
  borderBottom: 0,
  marginBottom: '20px',
});

const Section = styled('section', {
  borderBottom: `solid 1px ${style.grey}`,
});

const REMOVE_CONTRACT_MUTATION = gql`
  mutation($input: ContractRemoveInputType!) {
    contractRemove(input: $input) {
      id
      status
    }
  }
`;

class EditContract extends React.Component {
  state = {
    deleteEnabled: false,
  };

  toggleEnableDelete = () =>
    this.setState(prevState => ({
      deleteEnabled: !prevState.deleteEnabled,
    }));

  handleDelete = removeContract => {
    return removeContract({
      variables: {
        input: {
          id: this.props.contract.id,
        },
      },
    })
      .then(() => this.props.deleteContract(this.props.contract.id))
      .then(Notification.success('Customer deleted succesfully'))
      .catch(Notification.error('Error: Deleting customer failed'));
  };
  handleMainFieldsSave = values => {
    const { editContract } = this.props;
    return editContract(values)
      .then(Notification.success('Customer saved'))
      .catch(Notification.error('Error: Updating customer failed'));
  };

  handleCustomFieldsSave = values => {
    const { editCustomFields, contract } = this.props;
    return editCustomFields(contract.id, values)
      .then(Notification.success('Custom fields saved'))
      .catch(Notification.error('Error: Saving custom fields failed'));
  };

  render() {
    const { contract, isAdmin, match } = this.props;
    const { deleteEnabled } = this.state;

    return !contract ? (
      <EmptyState>Customer not found</EmptyState>
    ) : (
      <ContractContext contract={contract}>
        <PageHeader>
          <PageHeading>
            Edit Customer: <i>{contract.name}</i>
          </PageHeading>
        </PageHeader>
        <PageContent>
          <Wrapper>
            {contract.isFullyLoaded && (
              <Accordion>
                <AccordionPane title="Main fields">
                  <NoOffsetRow>
                    <Well>
                      <Form
                        contract={contract}
                        disabled={!isAdmin || contract.state !== 'DONE'}
                        save={this.handleMainFieldsSave}
                      />
                    </Well>
                  </NoOffsetRow>
                </AccordionPane>
                <AccordionPane title="Custom fields">
                  <Section>
                    <NoOffsetCol>
                      <h2>Edit customer fields</h2>
                      <i>Add or remove specific fields for the customer based on shipment type</i>
                    </NoOffsetCol>
                    <CustomFields contract={contract} save={this.handleCustomFieldsSave} />
                  </Section>
                </AccordionPane>
                <AccordionPane title="Notification templates">
                  <Templates contract={contract} match={match} />
                </AccordionPane>
                <AccordionPane title="Sticker configuration">
                  <StickerConfiguration contract={contract} />
                </AccordionPane>
              </Accordion>
            )}
          </Wrapper>
          {isAdmin && contract.isFullyLoaded && (
            <GraphQLProvider>
              <Mutation mutation={REMOVE_CONTRACT_MUTATION}>
                {removeContract => (
                  <Fragment>
                    {deleteEnabled && (
                      <RightAlign>
                        <ErrorMessage>
                          Be aware that deletion of this customer will delete all Locations, Recipients and Shipments
                          connected to this customer.
                        </ErrorMessage>
                      </RightAlign>
                    )}
                    <RightAlign>
                      <label>
                        Check to enable delete button{' '}
                        <input type="checkbox" value={deleteEnabled} onChange={this.toggleEnableDelete} />
                      </label>
                      <Button disabled={!deleteEnabled} onClick={() => this.handleDelete(removeContract)} danger>
                        Delete Customer
                      </Button>
                    </RightAlign>
                  </Fragment>
                )}
              </Mutation>
            </GraphQLProvider>
          )}
        </PageContent>
      </ContractContext>
    );
  }
}

const mapStateToProps = (state, props) => ({
  contract: state.contracts.items.filter(contract => contract.id === +props.match.params.contractId)[0],
  isAdmin: state.authentication.isAdmin,
});
const mapDispatchToProps = {
  deleteContract,
  editContract,
  editCustomFields,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditContract);
