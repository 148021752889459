import React, { Fragment } from 'react';
import { styled } from 'styletron-react';
import style from '../../../style';
import { CloseIcon } from '../../../components/Icons/Close';
import { isException } from '../status';

const Well = styled('div', {
  height: '100%',
  padding: '20px',
  marginBottom: '20px',
  backgroundColor: '#C8C2B9',
  border: '1px solid #E3E3E3',
  boxShadow: 'inset 0 1px 1px rgba(0,0,0,.05)',
});

const AddedTitle = styled('div', {
  marginTop: '0',
  fontWeight: '700',
  marginBottom: '1em',
});
const ShipmentPreview = styled('span', ({ status }) => ({
  width: '100%',
  display: 'flex',
  position: 'relative',
  flexWrap: 'wrap',
  padding: '0.5em 0',
  ...(isException(status) && {
    textDecoration: 'line-through',
  }),
}));

const PreviewChild = styled('span', {
  display: 'flex',
  flexWrap: 'wrap',
  flexBasis: '10%',
  paddingRight: '1.2em',
  ':first-child': {
    flexBasis: '20%',
  },
  ':last-child': {
    paddingRight: '0',
  },
});

const Icon = styled('div', {
  cursor: 'pointer',
  position: 'absolute',
  right: '0',
  top: '50%',
  transform: 'translateY(-50%)',
});

const ShipmentCard = styled('div', {
  background: '#fff',
  padding: '0.5em 1em',
  marginBottom: '0.5em',
  cursor: 'pointer',
  ':hover': {
    background: style.superLight,
  },
});

export default class ShipmentList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: undefined,
    };
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.shipments.length > this.props.shipments.length) {
      this.setState({
        selected: 0, // SF-355 Last added is now first in list
      });
    }
  }
  onSetSelected(index) {
    this.setState(s => ({
      selected: index === s.selected ? undefined : index,
    }));
  }
  render() {
    const { shipments, onRemove } = this.props;
    return (
      <Well>
        <AddedTitle>Registered shipments: {shipments.length}</AddedTitle>
        {shipments.map((c, i) => (
          <ShipmentCard key={i} onClick={() => this.onSetSelected(i)}>
            <div>
              <ShipmentPreview status={c.status}>
                <PreviewChild>{c.consignmentNumber}</PreviewChild>

                {c.assignee && c.receiver && (
                  <PreviewChild>
                    {c.assignee.firstName} {c.assignee.lastName}
                    <i>
                      {c.receiver.firstName} {c.receiver.lastName}
                    </i>
                    {c.receiver.email}
                  </PreviewChild>
                )}

                {!!!c.assignee && c.receiver && (
                  <Fragment>
                    <PreviewChild>
                      {c.receiver.firstName} {c.receiver.lastName}
                    </PreviewChild>
                    <PreviewChild>{c.receiver.email}</PreviewChild>
                  </Fragment>
                )}
                {!!!c.receiver && <PreviewChild>ANONYMIZED</PreviewChild>}

                {!isException(c.status) && (
                  <Icon onClick={() => onRemove(c)}>
                    <CloseIcon />
                  </Icon>
                )}
              </ShipmentPreview>
              {i === this.state.selected && (
                <div>
                  <h5>Details:</h5>
                  <p>Delivery location: {c.deliveryLocation.name}</p>
                  {c.referenceNumber && <p>Reference number: {c.referenceNumber}</p>}
                  {c.forwarder && <p>Forwarder: {c.forwarder}</p>}
                  {c.numberOfPackages && <p>Number of packages: {c.numberOfPackages}</p>}
                </div>
              )}
            </div>
          </ShipmentCard>
        ))}
      </Well>
    );
  }
}
