import { gql } from 'apollo-boost';

// Constants
export const CLEAR = 'shipment/Compact/CLEAR';
export const ADD = 'shipment/Compact/ADD';
export const ADD_LOADED = 'shipment/Compact/ADD_LOADED';
export const REMOVE = 'shipment/Compact/REMOVE';
export const MERGE = 'shipment/Compact/MERGE';
export const UPDATE = 'shipment/Compact/UPDATE';

// GraphQL queries
export const SHIPMENTS_QUERY = gql`
  query ConsignmentManualList($contractId: Int!, $consignmentNumber: String!) {
    contract(id: $contractId) {
      id
      consignment(consignmentNumber: $consignmentNumber) {
        id
        consignmentNumber
        requirePin
        status
        deliveryLocation {
          name
          requiresIdentityVerification
        }
        currentLocation {
          name
        }
      }
    }
  }
`;

// Action creators

export const addShipment = consignmentNumber => {
  return { type: ADD, consignmentNumber };
};
export const addShipmentLoaded = (consignmentNumber, shipment) => {
  return { type: ADD_LOADED, consignmentNumber, shipment };
};

export const removeShipment = consignmentNumber => {
  return { type: REMOVE, consignmentNumber };
};

export const clearShipments = () => {
  return { type: CLEAR };
};

export const mergeShipments = diff => {
  return { type: MERGE, diff };
};

export const updateShipments = shipments => {
  return { type: UPDATE, shipments };
};

export const fetchAndAddShipment = (contractId, number, client) => (dispatch, getState) => {
  const consignmentNumber = tryParseConsignmentNumber(number);
  const { selectedShipments } = getState().shipmentCompact;
  if (!consignmentNumber || selectedShipments[consignmentNumber]) return;

  dispatch(addShipment(consignmentNumber));
  client
    .query({
      query: SHIPMENTS_QUERY,
      variables: {
        contractId,
        consignmentNumber,
      },
    })
    .then(res => {
      const shipmentWithError = {
        consignmentNumber,
        error: true,
      };
      const shipment =
        res.data.contract && res.data.contract.consignment ? res.data.contract.consignment : shipmentWithError;

      dispatch(addShipmentLoaded(consignmentNumber, shipment));
    });
};

const tryParseConsignmentNumber = maybeNumber => {
  // Currently we have 2 versions of qr codes
  // pXXXXX
  // http(s)://host/contracts/{contractId}/shipments/{shipmentId}
  // In the future this should probably be updated to a smarter
  // algorithm for finding the number in the {maybeNumber}
  if (`${maybeNumber}`.length === 8 && `${maybeNumber}`.charAt(0) === '2')
    maybeNumber = `${maybeNumber}`.slice(-5);
  else if (`${maybeNumber}`.length === 9 && `${maybeNumber}`.charAt(0) === '2')
    maybeNumber = `${maybeNumber}`.slice(-6);

  console.log('maybeNumber', maybeNumber);
  if (maybeNumber.indexOf('http') === 0) {
    if (maybeNumber.indexOf('shipments') >= 0) {
      if (`${maybeNumber}`.slice(-6).charAt(0) !== '/' && `${maybeNumber}`.slice(-6).charAt(0) !== '0')
        return `${maybeNumber}`.slice(-6);
      return maybeNumber.slice(-5);
    }
    // If it's a unknown uri (probably a scanned qr code)
    // then it should be interpreted as a invalid code.
    return undefined;
  }

  if ((`${maybeNumber}`.length === 6 && `${maybeNumber}`.charAt(0) !== '0') || `${maybeNumber}`.length > 6)
    return `${maybeNumber}`;
  return `00000${maybeNumber}`.slice(-5);
};
