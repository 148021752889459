import React from 'react';
import { connect } from 'react-redux';
import { selectContract } from '../features/contracts/action-creators';
import { styled } from 'styletron-react';
import style from '../style';
import GraphQLProvider from '../components/GraphQLProvider';
import { Query } from 'react-apollo';
import { LOCATIONS_QUERY } from '../features/locations/graph';
import { FloatingSelect } from './Select';
import { Consumer as UserDataConsumer } from '../components/UserData';

const ContractBar = styled('div', {
  padding: '0.7em 0.5em',
  textAlign: 'center',
});

const SelectorWrapper = styled('div', {
  display: 'flex',
  justifyContent: 'center',
});

const Base = styled('div', {
  display: 'flex',
  margin: `0 -${style.compactBodyPadding}`,
  borderWidth: '1px 0 1px 0',
  borderStyle: 'solid',
  borderColor: '#ccc',
});

const flattenLocations = (locations, level = 0) =>
  (locations || []).reduce(
    (acc, location) => acc.concat([{ ...location, level }]).concat(flattenLocations(location.locations, level + 1)),
    []
  );

const locationLevelChars = '-------';

const RESET_FILTERS = {
  currentLocation: null,
  deliveryLocation: null,
};

const Main = ({ contracts, selectContract }) => (
  <UserDataConsumer>
    {({ data, setData }) => (
      <Base>
        <FloatingSelect
          label="Current customer"
          value={contracts.selected ? contracts.selected.id : undefined}
          onChange={e => {
            setData({
              main: { currentContract: +e.target.value },
              shipment: {
                ...data.shipment,
                filters: RESET_FILTERS,
              },
            });
            selectContract(+e.target.value || data.main.currentContract);
          }}
        >
          <option value={undefined}>- Select customer -</option>
          {contracts.items.map(c => (
            <option key={c.id} value={c.id}>
              {c.name}
            </option>
          ))}
        </FloatingSelect>

        <GraphQLProvider>
          <Query
            query={LOCATIONS_QUERY}
            skip={!contracts.selected}
            variables={{ contractId: parseInt(contracts.selected.id) }}
          >
            {({ data, loading }) => (
              <FloatingSelect
                label="Current location"
                disabled={!data || loading || !contracts.selected}
                value={
                  contracts.selected
                    ? contracts.selected.selectedLocationId
                      ? contracts.selected.selectedLocationId
                      : undefined
                    : undefined
                }
                onChange={e => {
                  setData({
                    main: { currentLocation: +e.target.value },
                  });
                  selectContract(contracts.selected.id, +e.target.value);
                }}
              >
                {data && <option value={undefined}>- Select Location -</option>}
                {data &&
                  flattenLocations(data.contract.locations)
                    .filter(location => location.status !== 'InActive')
                    .map(location => (
                      <option key={location.id} value={location.id}>
                        {locationLevelChars.substring(0, location.level)} {location.name}
                      </option>
                    ))}
              </FloatingSelect>
            )}
          </Query>
        </GraphQLProvider>
      </Base>
    )}
  </UserDataConsumer>
);

const ContractSelector = ({ contracts, selectContract, isCompact }) =>
  isCompact ? (
    <Main contracts={contracts} selectContract={selectContract} />
  ) : (
    <ContractBar>
      <SelectorWrapper>
        <Main contracts={contracts} selectContract={selectContract} />
      </SelectorWrapper>
    </ContractBar>
  );

export default connect(
  state => ({
    contracts: state.contracts,
  }),
  {
    selectContract,
  }
)(ContractSelector);
