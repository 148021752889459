import React, { Component } from 'react';
import { connect } from 'react-redux';
import { styled } from 'styletron-react';
import style from '../../../style';
import scanner from './scanner';
import Notification from '../../../utils/promiseNotification';
import { sendReminders, deliverMultiple } from '../action-creators';
import { fetchAndAddShipment, removeShipment, clearShipments, mergeShipments } from './action-creators';
import Identity from '../Identity';
import ActionButton from './ActionButton';
import ContractSelector from '../../../components/ContractSelector';
import Move from './Move';
import Panel from './Panel';
import ShipmentInput from './ShipmentInput';
import ShipmentList from './ShipmentList';
import { withApollo } from 'react-apollo';

class CompactShipment extends Component {
  state = {
    selectedShipments: {},
    showMovePanel: false,
    showIdentityPanel: false,
    identity: '',
  };

  componentDidMount() {
    this.scannerSubscription = scanner().subscribe(number => {
      this.addShipment(number, this.props.client);
    });
  }
  componentWillUnmount() {
    this.scannerSubscription.unsubscribe();
  }

  addShipment(number, client) {
    const { contractId, fetchAndAddShipment } = this.props;
    fetchAndAddShipment(contractId, number, client);
  }

  deliver = shipments => {
    const { deliverMultiple } = this.props;
    return deliverMultiple(shipments, this.state.identity)
      .then(Notification.success('Shipments marked as Delivered'))
      .catch(Notification.error('Error occured: Shipments not marked as Delivered'));
  };

  sendReminders = shipments => {
    const { sendReminders } = this.props;
    return sendReminders(shipments.map(s => s.id))
      .then(Notification.success('Reminders sent'))
      .catch(Notification.error('Error occured: Reminders not sent'));
  };

  hidePanel = () => this.setState(() => ({ showMovePanel: false }));
  togglePanel = () => this.setState(prevState => ({ showMovePanel: !prevState.showMovePanel }));

  hideIdentityPanel = () => this.setState(() => ({ showIdentityPanel: false }));
  toggleIdentityPanel = () =>
    this.setState(prevState => ({
      showIdentityPanel: !prevState.showIdentityPanel,
    }));

  render() {
    const { contractId, selectedShipments, match, removeShipment, clearShipments, mergeShipments, client } = this.props;
    const { showMovePanel, showIdentityPanel } = this.state;

    const shipments = Object.keys(selectedShipments)
      .map(number => ({
        ...selectedShipments[number],
        to: selectedShipments[number].id && `${match.path}/${selectedShipments[number].id}`,
      }))
      .filter(shipment => shipment)
      .sort((a, b) => (a.consignmentNumber > b.consignmentNumber ? 1 : -1));

    const shipmentsWithPin = shipments.filter(shipment => shipment.requirePin);
    const validSelectedShipments = shipments.filter(s => !s.error);
    const deliveredShipments = shipments.filter(s => s.status === 'Delivered');
    const deliverableShipments = shipments.filter(s => s.status === 'Registered');

    const identityRequired = validSelectedShipments.some(
      s => s.deliveryLocation && s.deliveryLocation.requiresIdentityVerification
    );

    return (
      <div>
        <ContractSelector isCompact />
        <Content>
          {shipments.length === 0 && (
            <Message>
              <p>
                To make a delivery or move a single parcel, scan the Smartflow label (or enter the shipment # manually)
                and then click on the parcel. On the next screen you can deliver or move the parcel.
              </p>
              <p>
                To move several parcels in one go, scan all Smartflow labels (or enter the shipment # manually) and then
                select Move!
              </p>
            </Message>
          )}

          <ShipmentList shipments={shipments} onRemove={number => removeShipment(number)} />
          <ShipmentInput onAdd={number => this.addShipment(number, client)} />

          {validSelectedShipments.length > 0 && (
            <Actions>
              <ActionMessage>
                Actions on {validSelectedShipments.length} shipment{validSelectedShipments.length > 1 && 's'}:
              </ActionMessage>
              <ActionButtons>
                {shipmentsWithPin.length === 0 && deliverableShipments.length > 0 && (
                  <ActionButton
                    label="Deliver"
                    onClick={identityRequired ? this.toggleIdentityPanel : () => this.deliver(deliverableShipments)}
                  />
                )}
                <ActionButton label="Send reminders" onClick={() => this.sendReminders(validSelectedShipments)} />
                <ActionButton label="Move" onClick={this.togglePanel} />
                <ActionButton danger label="Clear selection" onClick={clearShipments} />
              </ActionButtons>
            </Actions>
          )}
          {shipmentsWithPin.length > 0 && (
            <Message>
              <i>
                It's not possible to deliver these at once, the following shipments needs to be delivered from their
                respective details view as they require a pin code:{' '}
                {shipmentsWithPin.map(s => s.consignmentNumber).join()}.
              </i>
            </Message>
          )}
          {deliveredShipments.length > 0 && deliverableShipments.length > 0 && (
            <Message>
              <i>
                Some of the selected shipments are already delivered but you may still deliver the following shipments:{' '}
                {deliverableShipments.map(s => s.consignmentNumber).join()}.
              </i>
            </Message>
          )}
          <Panel onClose={this.hidePanel} title={'Move'}>
            {showMovePanel && (
              <Move
                contractId={contractId}
                shipments={shipments.filter(s => s && !s.error)}
                onMoved={diff => {
                  mergeShipments(diff);
                  this.hidePanel();
                }}
              />
            )}
          </Panel>
          <Panel onClose={this.hideIdentityPanel} title={'Identification required'}>
            {showIdentityPanel && (
              <Identity
                identity={this.state.identity}
                onChange={identity => this.setState({ identity })}
                submit={() => this.deliver(deliverableShipments)}
                close={this.hideIdentityPanel}
              />
            )}
          </Panel>
        </Content>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  contractId: state.contracts.selected.id,
  selectedShipments: state.shipmentCompact.selectedShipments,
});
const mapDispatchToProps = {
  sendReminders,
  deliverMultiple,
  fetchAndAddShipment,
  removeShipment,
  clearShipments,
  mergeShipments,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(CompactShipment));

// Styled components
const Content = styled('div', {
  paddingTop: '10px',
});

const Message = styled('div', {});

const Actions = styled('div', {
  background: 'white',
  borderRadius: '0.25rem',
  overflow: 'hidden',
  marginTop: '1rem',
});

const ActionMessage = styled('h2', {
  fontSize: '1rem',
  padding: '0.5rem',
  margin: 0,
  fontFamily: style.bodyFont,
});

const ActionButtons = styled('div', {
  display: 'flex',
  borderTop: `1px solid ${style.light}`,
  flexWrap: 'wrap',
});
