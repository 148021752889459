import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { ApolloProvider } from '@apollo/react-hooks';
import getClient from './ApolloGraphClient';

const GraphQLProvider = ({ user, children }) => {
  const [client, setClient] = useState(null);

  useEffect(() => {
    const client = getClient(user ? user.access_token : null);
    setClient(client);
  }, [user]); // Trigger effect when user changes
  if (client) {
    return <ApolloProvider client={client}>{children}</ApolloProvider>;
  }
};

// Connecting the GraphQLProvider to Redux
const ConnectedGraphQLProvider = connect(state => ({
  user: state.oidc && state.oidc.user,
}))(GraphQLProvider);

export { GraphQLProvider };
export default ConnectedGraphQLProvider;
