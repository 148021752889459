/* global BrowserPrint */
import leftpad from 'leftpad';
import { formatDate } from '../../utils/dateUtil';
import handlebars from 'handlebars/dist/cjs/handlebars';
import { shipment as buildShipmentURI, location as buildLocationURI } from '../../identifiers';

/*
ZPL Commands:
https://www.zebra.com/content/dam/zebra/manuals/en-us/software/zpl-zbi2-pm-en.pdf
^XA: start format
^LL: label length
^FO: field origin
^A: font, ^A0N36,36: (0: scalable, Nx,y)
^FD: field data
^FS: field separator
^XZ: end format
^CI5{0}^CI13: Swedish char format
*/

function MyCompiler() {
  handlebars.JavaScriptCompiler.apply(this, arguments);
}
MyCompiler.prototype = new handlebars.JavaScriptCompiler();

// Use this compile to compile BlockStatment-Blocks
MyCompiler.prototype.compiler = MyCompiler;

MyCompiler.prototype.nameLookup = function(parent, name, type) {
  if (type === 'context') {
    return this.source.functionCall('helpers.lookupLowerCase', '', [parent, JSON.stringify(name)]);
  } else {
    return handlebars.JavaScriptCompiler.prototype.nameLookup.call(this, parent, name, type);
  }
};

var env = handlebars.create();
env.registerHelper('lookupLowerCase', function(parent, name) {
  return Object.keys(parent)
    .filter(key => key.toLowerCase() === name.toLowerCase())
    .map(key => parent[key])[0];
});

env.JavaScriptCompiler = MyCompiler;

const statusheader = '~HQES';
const commands = {
  start: '^XA',
  length: length => `^LL${length}`,
  origin: (x, y, justification = 0) => `^FO${x},${y},${justification}`,
  font: (height, width) => `^A0N${height},${width}`,
  block: (width, maxLines) => `^FB${width},${maxLines}`,
  utf: '^CI28',
  useArialFont: '^CWT,E:ARI000.FNT',
  fieldSeparator: '^FS',
  fieldData: '^FD',
  field: data => commands.fieldData + data + commands.fieldSeparator,
  end: '^XZ',
  swedish: '^CI5{0}^CI13',
  norwegian: '^CI4{0}^CI13',
  barCode: (height, printInterpretationLineCode) => `^BCN,${height},${printInterpretationLineCode ? 'Y' : 'N'},N,N,N`,
  resetBarCode: 'BY2,3',
  qrCode: data => `^BQN,2,3,A,A,A${commands.field('QA,' + data)}`,
};

const formatLine = (lineConfiguration, shipment, x, y) => {
  const lines = (lineConfiguration(shipment) || '').split('\n').filter(line => line);
  if (lines.length < 1 || lines.length > 2) return;
  return lines.length === 1
    ? commands.origin(x, y) + commands.font(30, 30) + commands.field(lines[0])
    : commands.font(20, 20) +
        commands.origin(x, y) +
        commands.field(lines[0]) +
        commands.origin(x, y + 20) +
        commands.font(20, 20) +
        commands.field(lines[1]);
};

const leftMargin = 150;
const topMargin = 5;

const format = (shipment, configuration, number = undefined) =>
  commands.start +
  commands.useArialFont +
  commands.utf +
  commands.origin(0, topMargin + 10) +
  commands.qrCode(buildShipmentURI(shipment.contract.id, shipment.consignmentNumber)) +
  commands.origin(0, topMargin + 170) +
  commands.font(15, 15) +
  commands.field(formatDate(shipment.registeredDate).substring(0, 16)) +
  commands.origin(leftMargin, topMargin + 20) +
  commands.font(50, 50) +
  commands.field(leftpad(shipment.consignmentNumber, 5, '0')) +
  ((number &&
    // right justification
    commands.origin(470, 30, 1) + commands.font(45, 45) + commands.field(`${number}/${shipment.numberOfPackages}`)) ||
    '') +
  formatLine(configuration.line1, shipment, leftMargin, topMargin + 70) +
  formatLine(configuration.line2, shipment, leftMargin, topMargin + 115) +
  formatLine(configuration.line3, shipment, leftMargin, topMargin + 160) +
  commands.end;

export const getDefaultPrinter = () =>
  new Promise((resolve, reject) => {
    BrowserPrint.getDefaultDevice('printer', printer => resolve(printer), error => reject(error));
  });

export const getLocalPrinters = () =>
  new Promise((resolve, reject) => {
    BrowserPrint.getLocalDevices(
      printers =>
        resolve(
          (printers.printer || []).reduce(
            (acc, printer) => ({
              ...acc,
              [printer.uid]: printer,
            }),
            {}
          )
        ),
      error => reject(error)
    );
  });

export const statusCodes = {
  1: 'Ready to print',
  2: 'Paper out',
  3: 'Ribbon out',
  4: 'Media door open',
  5: 'Cutter fault',
  6: 'Printhead overheating',
  7: 'Motor overheating',
  8: 'Printhead fault',
  9: 'Incorrect printhead',
  10: 'Printer Paused',
  11: 'Unknow error',
};

export const getPrinterStatus = printer =>
  new Promise((resolve, reject) => {
    printer.sendThenRead(
      statusheader,
      function(text) {
        var statuses = [];
        const isReady = text.charAt(70) === '0';
        var media = text.charAt(88);
        var head = text.charAt(87);
        var pause = text.charAt(84);
        // check each flag that prevents printing
        if (isReady) {
          resolve([1]);
          return;
        }
        if (media === '1') statuses.push(2);
        if (media === '2') statuses.push(3);
        if (media === '4') statuses.push(4);
        if (media === '8') statuses.push(5);
        if (head === '1') statuses.push(6);
        if (head === '2') statuses.push(7);
        if (head === '4') statuses.push(8);
        if (head === '8') statuses.push(9);
        if (pause === '1') statuses.push(10);
        if (!isReady && statuses.length === 0) statuses.push(11);
        reject(statuses);
      },
      error => reject(error)
    );
  });

const printCommand = (printer, command) =>
  new Promise((resolve, reject) => {
    console.log(command);
    printer.send(command, () => resolve(), error => reject(error));
  });

const printShipment = (printer, shipment, configuration) =>
  shipment.type.toLowerCase() === 'letter'
    ? printCommand(printer, format(shipment, configuration))
    : Array.apply(null, { length: shipment.numberOfPackages }).reduce(
        (chain, _, i) =>
          chain.then(() => printCommand(printer, format(shipment, configuration, shipment.numberOfPackages - i))),
        Promise.resolve()
      );

export const printShipments = (printer, shipments, configuration) => {
  const compiled = ['line1', 'line2', 'line3'].reduce(
    (acc, key) => ({
      ...acc,
      [key]: env.compile(configuration[key] || ''),
    }),
    {}
  );
  return getPrinterStatus(printer).then(() => Promise.all(shipments.map(c => printShipment(printer, c, compiled))));
};

export const printLocations = (printer, locations) =>
  getPrinterStatus(printer).then(() =>
    Promise.all(
      locations.map(location => {
        const command =
          commands.start +
          commands.useArialFont +
          commands.utf +
          commands.origin(0, topMargin + 45) +
          commands.qrCode(buildLocationURI(location.contract.id, location.id)) +
          commands.origin(140, topMargin + 95) +
          commands.font(25, 25) +
          commands.field(location.name) +
          commands.end;
        return printCommand(printer, command);
      })
    )
  );
