import React, { useRef, useEffect, useState } from 'react';
import { styled } from 'styletron-react';

const Collapse = styled('div', ({ $duration, $contentHeight, $collapsed }) => ({
  transition: `transform ${$duration}ms ease-in-out, height ${$duration}ms ease-in-out, opacity ${$duration}ms ease-in-out`,
  transform: 'translateY(0)',
  opacity: '1',
  height: `${$contentHeight}px`,
  ...($collapsed && {
    transform: 'translateY(-100%)',
    opacity: '0',
    height: '0',
  }),
}));

const CollapseContainer = styled('div', {
  overflowY: 'hidden',
});

const Collapsable = ({ children, collapsed, duration = 400 }) => {
  const contentRef = useRef(null);
  const [contentHeight, setContentHeight] = useState(0);

  useEffect(() => {
    if (!collapsed) {
      const handleResize = () => {
        setContentHeight(contentRef.current.offsetHeight);
      };
      handleResize(); // Set initial height
      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }
  }, [collapsed]);

  return (
    <CollapseContainer>
      <Collapse $collapsed={collapsed} $duration={duration} $contentHeight={contentHeight}>
        <div ref={contentRef}>{children}</div>
      </Collapse>
    </CollapseContainer>
  );
};

export default Collapsable;
