import { NotificationManager } from 'react-notifications';

export const info = message => result => {
  NotificationManager.info(message);
  return result;
};
export const success = message => result => {
  NotificationManager.success(message);
  return result;
};
export const warning = message => result => {
  NotificationManager.warning(message);
  return result;
};
export const error = message => error => {
  NotificationManager.error(message);
  throw error;
};

export default {
  info,
  success,
  warning,
  error,
};
