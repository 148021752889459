import React from 'react';
import { toQuery, fromQuery } from '../../../utils/uri';
import {
  PageHeader,
  PageHeading,
  PageContent,
  makeLinkWrapper,
  StyledLink as PageLink,
} from '../../../components/Page';
import { styled } from 'styletron-react';
import { GraphPagination as Pagination } from '../../../components/Pagination';
import SearchField from '../../../components/SearchField';
import List, { makeListItem } from '../../../components/List';
import GraphQLProvider from '../../../components/GraphQLProvider';
import { Query } from 'react-apollo';
import { gql } from 'apollo-boost';
import debounce from 'lodash.debounce';
import { connect } from 'react-redux';
const LinkListItem = makeListItem(PageLink);
const LinkWrapper = makeLinkWrapper(LinkListItem);

const Search = styled('div', {
  marginLeft: 'auto',
  flexBasis: '400px',
});

const Recipient = styled('div', {
  display: 'flex',
});
const Name = styled('span', {
  flexBasis: '180px',
  marginRight: '1rem',
});

const USERS_QUERY = gql`
  query ServiceUsersListing($contractId: Int!, $after: String, $before: String, $first: Int, $query: String) {
    contract(id: $contractId) {
      id
      serviceUsers(after: $after, before: $before, first: $first, query: $query) {
        edges {
          node {
            id
            firstName
            lastName
            email
          }
        }
        pageInfo {
          startCursor
          endCursor
          hasNextPage
          hasPreviousPage
        }
      }
    }
  }
`;

class SearchUsers extends React.Component {
  componentDidMount() {
    this.debounceQuery = debounce((query, history, match, queryParams) => {
      history.replace(
        `${match.path}?${toQuery({
          query,
          ...(queryParams.first && {
            first: queryParams.first,
          }),
        })}`
      );
    }, 300);
  }
  render() {
    const { match, location, history, contractId } = this.props;
    const queryParams = fromQuery(location.search);
    return (
      <div>
        <GraphQLProvider>
          <Query query={USERS_QUERY} variables={{ contractId: parseInt(contractId), ...queryParams }}>
            {({ data, loading, error }) => (
              <div>
                {error && new Error(error.message)}
                <PageHeader>
                  <PageHeading>Recipients</PageHeading>
                  <Search>
                    <SearchField
                      placeholder={'Search recipient'}
                      defaultValue={queryParams.query}
                      onChange={e => this.debounceQuery(e.target.value, history, match, queryParams)}
                      loading={loading}
                    />
                  </Search>
                </PageHeader>
                <PageContent>
                  <List>
                    {!loading &&
                      data.contract.serviceUsers.edges
                        .map(e => e.node)
                        .map(su => (
                          <LinkWrapper key={su.id} to={`${match.path}/edit/${su.id}`}>
                            <Recipient>
                              <Name>
                                {su.firstName} {su.lastName}
                              </Name>
                              <span>{su.email}</span>
                            </Recipient>
                          </LinkWrapper>
                        ))}
                  </List>
                  {!loading && (
                    <Pagination
                      match={match}
                      location={location}
                      history={history}
                      pageInfo={data.contract.serviceUsers.pageInfo}
                    />
                  )}
                </PageContent>
              </div>
            )}
          </Query>
        </GraphQLProvider>
      </div>
    );
  }
}

export default connect(state => ({
  contractId: state.contracts.selected.id,
}))(SearchUsers);
